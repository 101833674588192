import { UrlParams } from './url-params.model';
import { environment } from '../../../../environments/environment';
import { SETTINGS } from './../../../app.constants';

export class Url {

    // tslint:disable-next-line:variable-name
    private _url: string;
    // tslint:disable-next-line:variable-name
    private _queryMode = false;
    // tslint:disable-next-line:variable-name
    private _queryParams: UrlParams;

    /**
     *
     * @param relativeEndPointPath (example: goesapp/reference/data/permittedDocuments)
     */
    constructor(path: string, queryParams?: UrlParams, absolutePath: boolean = false){

        if (!SETTINGS.useStaticTestAPIData){
            if (!absolutePath){
                this._url = environment.uriEndpoint + path;
            } else {
                this._url = path;
            }
        } else {
            this._url = '/';
        }

        if (queryParams){
            this._queryMode = true;
            this._queryParams = queryParams;
            this.appendUrlWithParams();
        }

    }

    public toString(): string {
        const urlSafeString: string = encodeURI(this._url);
        return urlSafeString;
    }

    protected appendUrlWithParams(): void {
        const paramsString = this._queryParams.toString();
        this._url += paramsString;
    }

}

