import { SETTINGS } from './../../app.constants';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Document } from '../models/document.model';
import { DriversLicense } from '../models/drivers-license.model';
import { ErrorHandlerService } from './error-handler.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class UpdateDocumentService {

  updatedDocument: any;
  private _passports: Document[];
  private _license: DriversLicense;
  private _residentCard: Document;
  private _docToEdit: any = null;


  constructor(private http: HttpClient,
              private errorHandler: ErrorHandlerService) {}

  // TODO remove membershipOrPassId; Its used for testing
  public loadDocuments(userId: string, membershipOrPassId?: string): Observable<any>{
    let requestURI = '';
    if (SETTINGS.useStaticTestAPIData) {
        requestURI = SETTINGS.staticTestAPIRoot + 'document-data.json';
    }
    else {
        requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/ges/documents';

        if (membershipOrPassId) {
          requestURI += '?passid=' + membershipOrPassId;
        }
    }

    return this.http.get(requestURI).pipe(
      map((res: any) => {
        if (res) {
          const data = res;

          if (data.hasOwnProperty('passports') && data.passports) {
            this._passports = new Array<Document>();
            data.passports.forEach((doc: any) => {
              if (doc.hasOwnProperty('type') && doc.type === 'PT') {
                const passport = new Document(doc);
                if (passport) {
                  this._passports.push(passport);
                }
              }
            });
          }

          if (data.hasOwnProperty('license') && data.license) {
            const license = new DriversLicense(data.license);
            if (license) {
              this._license = this.convertDocumentToLicense(data.license);
            }
          }

          if (data.hasOwnProperty('residentCard') && data.residentCard) {
            const residentCard = new Document(data.residentCard);
            if (residentCard) {
              this._residentCard = residentCard;
            }
          }
        }
        return of(true);
      }),
      catchError(error => this.errorHandler.handleError(error)));
  }

  // TODO remove membershipOrPassId; Its used for testing
  updateDocument(userId: string, document: any, membershipOrPassId?: string) {

    let requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/ges/documents';
    if (membershipOrPassId) {
      requestURI += '?passid=' + membershipOrPassId;
    }

    let requestObj: any;

    if (document instanceof DriversLicense) {
      requestObj = this.serializeDocument(this.convertLicenseToDocument(document as DriversLicense));
    }
    else {
      requestObj = this.serializeDocument( document );
    }

    if (SETTINGS.useStaticTestAPIData) {
      this._docToEdit = null;
      this.updatedDocument = document;

      return of(true);
    }
    else {
      return this.http.put(requestURI, requestObj).pipe(
        map(res => {
            // Does not return a response; 200 or error
            this.updatedDocument = document; // Set to respose object
            this._docToEdit = null;
            return of(true);
        }),
        catchError(error => this.errorHandler.handleError(error)));
    }
  }

  public getPassports(): Array<Document> {
    return this._passports;
  }

  public getLicense(): DriversLicense {
    return this._license;
  }

  public getResidentCard(): Document {
    return this._residentCard;
  }

  public setDocToEdit(document: any) {
    this._docToEdit = document;
  }

  public getDocToEdit() {
    return this._docToEdit;
  }

  private convertDocumentToLicense(jsonObject: any): DriversLicense {
    const driverLicense = new DriversLicense(jsonObject);

    driverLicense.haveLicense = 'Y';

    if (jsonObject.hasOwnProperty('documentNumber')) {
      driverLicense.licenseNumber = jsonObject.documentNumber;
    }

    if (jsonObject.hasOwnProperty('cdLIndicator')) {
      if (typeof jsonObject.cdLIndicator === 'boolean') {
          if (jsonObject.cdLIndicator) {
              driverLicense.isCDL = 'Y';
          }
          else {
              driverLicense.isCDL = 'N';
          }
      }
    }

    if (jsonObject.hasOwnProperty('edLIndicator')) {
      if (typeof jsonObject.edLIndicator === 'boolean') {
        if (jsonObject.edLIndicator) {
            driverLicense.isEDL = 'Y';
        }
        else {
            driverLicense.isEDL = 'N';
        }
      }
    }

    if (jsonObject.hasOwnProperty('hazmatIndicator')) {
      if (typeof jsonObject.hazmatIndicator === 'boolean') {
        if (jsonObject.hazmatIndicator) {
            driverLicense.isHazmat = 'Y';
        }
        else {
            driverLicense.isHazmat = 'N';
        }
      }
    }

    return driverLicense;
  }

  private convertLicenseToDocument(license: DriversLicense): any {
    const document: any = new Document(license);
    document.type = 'DL';

    if (license.hasOwnProperty('firstName')) {
      document.givenName = license.firstName;
    }

    if (license.hasOwnProperty('lastName')) {
      document.surName = license.lastName;
    }

    if (license.hasOwnProperty('licenseNumber')) {
      document.documentNumber = license.licenseNumber;
    }

    if (license.hasOwnProperty('isCDL')) {
      document.cdLIndicator = license.isCDL === 'Y' ? true : false;
    }

    if (license.hasOwnProperty('isEDL')) {
      document.edLIndicator = license.isEDL === 'Y' ? true : false;
    }

    if (license.hasOwnProperty('isHazmat')) {
        document.hazmatIndicator = license.isHazmat === 'Y' ? true : false;
    }

    if (license.hasOwnProperty('dateOfBirth')) {
      document.dateOfBirth = license.dateOfBirth;
    }

    if (license.hasOwnProperty('expirationDate')) {
      document.expiration = license.expirationDate;
    }

    if (license.hasOwnProperty('countryOfIssuance')) {
      document.countryOfIssuance = license.countryOfIssuance;
    }

    return document;
  }

  private serializeDocument(document: any): any {

    const doc = {
      type: document.type || '',
      citizenshipProofInd: document.citizenshipProofInd,
      admissibilityProofInd: document.admissibilityProofInd,
      lprProofInd: document.lprProofInd,
      docId: document.docId || null,
      hazmatIndicator : document.hazmatIndicator || '',
      edLIndicator : document.edLIndicator || '',
      cdLIndicator : document.cdLIndicator || '',
      countryOfIssuanceIsoCode: document.countryOfIssuance.isoCountryCode,
      dateOfBirth: document.dateOfBirth.getSimpleString(),
      dateOfIssuance: document.dateOfIssuance.getSimpleString(),
      documentNumber: document.documentNumber,
      expiration: document.expiration.getSimpleString(),
      freeTextState: document.freeTextState,
      givenName: document.givenName,
      // 'issuingAuthority': document.issuingAuthority,
      middleName: document.middleName,
      stateOfIssuance: document.stateOfIssuance,
      suffix: '',
      surName: document.surName,
      registryGroup: document.registryGroup || '',
      visaClass: document.class || '',
      aNumber: document.aNumber || '',
      abtcInd: document.abtcInd || '',
      documentKey: document.documentKey || '',
      verifyStatusCode: document.verified || ''
    };

    return doc;
  }
}
