import { EventEmitter } from '@angular/core';
import { ObservableQueueFunction } from './observable-queue-function';

/**
 * Use this class when you have a series of functions to call in a service that are
 * dependent on each other. Use with observable service functions only. Calling
 * class will need to subscribe to nextEmitter and perform actions before executing next.
 */
export class ObservableQueue {

    public nextEmitter:EventEmitter<any> = new EventEmitter<any>();
    public errorEmitter:EventEmitter<any> = new EventEmitter<any>();
    public completeEmitter:EventEmitter<any> = new EventEmitter<any>();
    // -------------------------------------------------------------
    private _functionArray:Array<ObservableQueueFunction> = new Array<ObservableQueueFunction>();
    private _isExecuting:boolean = false;
    public get isExecuting():boolean {
        return this._isExecuting;
    }

    constructor(){

    }

    /**
     *
     * @param f Only functions that return Observable will work
     */
    public add(func:ObservableQueueFunction):void {
        this._functionArray.push(func);
    }

    /**
     * Caller must execute next
     */
    public next(){
        let self:ObservableQueue = this;
        this._isExecuting = true;
        if(this._functionArray.length > 0){
            let func:ObservableQueueFunction = this._functionArray.shift();
            func.apply().subscribe(
                (res:any) => {
                    if(this._isExecuting) {

                        if(this._functionArray.length === 0){
                            this._isExecuting = false;
                            self.completeEmitter.emit(null);
                        } else {
                            self.nextEmitter.emit(res);
                        }

                    }
                },
                (error:any) => {
                    if(this._isExecuting) {
                        self.errorEmitter.emit(error);
                        if(this._functionArray.length === 0){
                            this._isExecuting = false;
                        }
                    }
                }
            );
        } else {
            this._isExecuting = false;
            this.completeEmitter.emit(null);
        }

    }

    public start(){
        this.next();
    }

    public stop(){
        this.empty();
    }

    public empty():void {
        this._isExecuting = false;
        while(this._functionArray.length > 0){
            this._functionArray.pop();
        }
    }

}
