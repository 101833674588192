
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ApplicationService } from './application.service';
import { ModalService } from '../../shared/modals/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from './error-handler.service';
import { GESAppointmentModel } from '../models/appointments/ges-appointment.model';
import { AppointmentModel } from '../models/appointments/appointment.model';
import { Location } from '../models/appointments/location.model';
import { SchedulerModel } from '../models/appointments/scheduler.model';
import { User } from '../../common/models/user';
import { ApplicationMetadata } from '../../common/models/application-metadata.model';
import { Url } from '../../common/models/url/url.model';
import { UrlParam } from '../../common/models/url/url-param.model';
import { UrlParams } from '../../common/models/url/url-params.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SETTINGS } from '../../app.constants';

@Injectable()
export class ScheduleService {

    // tslint:disable-next-line:variable-name
    private _appointmentModel: AppointmentModel = null;
    public get appointmentModel(): AppointmentModel {
        return this._appointmentModel;
    }

    // tslint:disable-next-line:variable-name
    private _appointmentHistoryList: Array<AppointmentModel> = Array<AppointmentModel>();
    public get appointmentHistoryList(): Array<AppointmentModel> {
        return this._appointmentHistoryList;
    }

    // tslint:disable-next-line:variable-name
    private _gesAppointmentModel: GESAppointmentModel = null;
    public get gesAppointmentModel(): GESAppointmentModel {
        return this._gesAppointmentModel;
    }

    // tslint:disable-next-line:variable-name
    private _applicationMetadata: ApplicationMetadata = null;
    public get applicationMetadata(): ApplicationMetadata {
        return this._applicationMetadata;
    }
    public set applicationMetadata(application: ApplicationMetadata) {
        this._applicationMetadata = application;
    }

    // tslint:disable-next-line:variable-name
    private _showDashboardNotification = false;
    public get showDashboardNotification(): boolean {
        return this._showDashboardNotification;
    }
    public set showDashboardNotification(value: boolean) {
        this._showDashboardNotification = value;
    }

    // tslint:disable-next-line:variable-name
    private _focusedAppointmentApp: ApplicationMetadata = null;
    public get focusedAppointmentApp(): ApplicationMetadata {
        return this._focusedAppointmentApp;
    }
    public set focusedAppointmentApp(value: ApplicationMetadata) {
        this._focusedAppointmentApp = value;
    }

    // tslint:disable-next-line:variable-name
    private _notificationMessage = '';
    public get notificationMessage(): string {
        return this._notificationMessage;
    }
    public set notificationMessage(value: string) {
        this._notificationMessage = value;
    }

    // tslint:disable-next-line:variable-name
    private _user: User;

    constructor(
      private errorHandler: ErrorHandlerService,
      private authService: AuthService,
      private translate: TranslateService,
      private authHttp: HttpClient){
          authService.authorizedUser$.subscribe(
              user => { this._user = user; }
        );
    }

    public createAppointment(gesAppointment: GESAppointmentModel): Observable<boolean> {

        // todo: create translation tags for message
        // this._notificationMessage = 'APPOINTMENT.UPDATED';
        // this._showDashboardNotification = true;
        //
        this._gesAppointmentModel = gesAppointment;

        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
          };

        let url: string = environment.uriEndpoint + 'v1/goesapp/appointment/';

        if (gesAppointment.locationId === SETTINGS.REMOTE_LOCATION_ID) {
            url = environment.uriEndpoint + 'v1/goesapp/remote/appointment/';
        }

        const body: any = JSON.stringify(gesAppointment.toJSONforCreate());

        return this.authHttp.post(url, body, headers).pipe(
            map((res: any) => {
                return true;
            }),
            catchError(error => this.errorHandler.handleError(error)), );
    }

    public getLocationDetails(locationId: number): Observable<Location> {
        const url: Url = new Url(environment.uriSchedulerEndpoint + 'locations/' + locationId, null, true);

        return this.authHttp.get(url.toString()).pipe(
            map((res: any) => {
                const data = res;
                const location: Location = new Location(data);
                return location;
            }),
            catchError(error => this.errorHandler.handleError(error)), );
    }

    public getLocationMap(locationId: number): Observable<string> {
        const url: Url = new Url(environment.uriSchedulerEndpoint + 'locations/' + locationId + '/image', null, true);

        return this.authHttp.get(url.toString(), {observe: 'response', responseType: 'text'}).pipe(
            map((res: any) => {
                const data: string = res.body;
                let filetype = 'image/png;';
                if (res.headers){
                    filetype = res.headers.get('content-type');
                }
                if (filetype === 'image/tif') {
                    filetype = 'image/tiff';
                }
                let image: string = null;
                if (data && data.length && data.length > 0){
                    image = 'data:' + filetype + ';base64, ' + data;
                }
                return image;
            }),
            catchError(
                (error: Response) => {
                    if (error.status === 404){
                        return observableThrowError('no map found');
                    }
                    return this.errorHandler.handleError(error);
                }
            ), );
    }

    public getAppointment(applicationId: string, source: string): Observable<AppointmentModel> {

        const params: UrlParams = new UrlParams();
        const secretAppCode = source + '-' + applicationId + 'N';
        const url: Url = new Url(environment.uriEndpoint + 'v1/goesapp/appointments/' + secretAppCode, null, true);

        return this.authHttp.get(url.toString()).pipe(
            map((res: any) => {

                const appointments: AppointmentModel[] = new Array<AppointmentModel>();
                res.forEach((appt: any) => {
                    appointments.push(new AppointmentModel(appt));
                });

                let returnAppointment: AppointmentModel = null;
                appointments.forEach(appointment => {
                    if (!returnAppointment) {
                        returnAppointment = appointment;
                    } else if (returnAppointment.createdDateObject < appointment.createdDateObject) {
                        returnAppointment = appointment;
                    } else if (returnAppointment.createdDateObject.getTime() === appointment.createdDateObject.getTime()) {
                        if (appointment.eventTypeId === SchedulerModel.APPOINTMENT_TYPE_CREATE_ID ||
                             appointment.eventTypeId === SchedulerModel.APPOINTMENT_TYPE_OFFICER_CREATE_ID) {
                            returnAppointment = appointment;
                        }
                    }
                });
                this._appointmentModel = returnAppointment;
                return returnAppointment;
            }),
            catchError(error => this.errorHandler.handleError(error)), );
    }

    public updateAppointment(gesAppointment: GESAppointmentModel): Observable<any> {

        // todo: create translation tags for message
        // this._notificationMessage = 'Updated GES Appointment.';
        // this._showDashboardNotification = true;

        // put  /api/v1/goesapp/appointment/  Update and send Approved Appointment Schedule to GES
        this._gesAppointmentModel = gesAppointment;

        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        };

        let url: string = environment.uriEndpoint + 'v1/goesapp/appointment/';
        if (gesAppointment.locationId === SETTINGS.REMOTE_LOCATION_ID) {
            url = environment.uriEndpoint + 'v1/goesapp/remote/appointment/';
        }

        const body: any = JSON.stringify(gesAppointment.toJSONforUpdate());

        return this.authHttp.put(url, body, httpOptions).pipe(
            map(res => {
                return true;
            }),
            catchError(error => this.errorHandler.handleError(error)), );
    }

    public deleteAppointment(gesAppointment: GESAppointmentModel): Observable<boolean> {
        // delete  /api/v1/goesapp/appointment/  Delete and send Approved Appointment Schedule to GES
        this._gesAppointmentModel = gesAppointment;

        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        };

        const url: string = environment.uriEndpoint + 'v1/goesapp/appointment/';
        const body: any = JSON.stringify(gesAppointment.toJSONforDelete());

        return this.authHttp.put(url, body, httpOptions).pipe(
            map(res => {
                return true;
            }),
            catchError(error => this.errorHandler.handleError(error)), );
    }

    //
    // ROUTES
    //

    public returnRescheduleUri(app: ApplicationMetadata, user: User, remoteAllowed?: boolean): string {
        return this.generateRescheduleUri(app, user, AppointmentModel.TYPE_UPDATE, remoteAllowed);
    }

    public returnNewAppointmentUri(app: ApplicationMetadata, user: User, remoteAllowed?: boolean): string {
        return this.generateRescheduleUri(app, user, AppointmentModel.TYPE_CREATE, remoteAllowed);
    }

    private generateRescheduleUri(app: ApplicationMetadata, user: User, type: string, remoteAllowed?: boolean): string {
        let token = '';
        // Only need token when not using cookie
        if (!environment.authenticateWithCookie) {
            token = this.authService.token;
        }
        let programName = '';

        if (app.programs && app.programs.length > 0){
            programName = app.programs[0];
        }

        const params: UrlParams = new UrlParams();
        params.add(new UrlParam('lang', this.translate.currentLang));
        if (type !== AppointmentModel.TYPE_CREATE && this.appointmentModel){
            params.add(new UrlParam('appointmentID', this.appointmentModel.id));
            params.add(new UrlParam('locationID', this.appointmentModel.locationId));
            params.add(new UrlParam('appCode', app.source + '-' + app.applicationId));
            params.add(new UrlParam('reqReason', true));
        } else {
            params.add(new UrlParam('appCode', app.applicationId));
        }
        params.add(new UrlParam('zone', user.userId ));
        params.add(new UrlParam('token', token));
        params.add(new UrlParam('returnUrl', encodeURIComponent(SETTINGS.schedulerReturnService) ));
        params.add(new UrlParam('service', programName.toLowerCase()));
        params.add(new UrlParam('type', type));
        params.add(new UrlParam('finExt', true));

        params.add(new UrlParam('remoteAllowed', remoteAllowed ? remoteAllowed : false));

        let url: Url = new Url(environment.uriScheduler + 'schedule-interview/location', params, true);
        if (remoteAllowed) {
            url = new Url(environment.uriScheduler + 'schedule-interview/schedule-remote', params, true);
        }
        return url.toString();
    }

    public clearNotification(){
        this._notificationMessage = '';
        this._showDashboardNotification = false;
    }

}
