import { Observable } from 'rxjs';

/**
 * Use with ServiceFunctionQueue
 */
export class ObservableQueueFunction {

    func:Function;
    args:Array<any>;
    context:any;

    constructor(f:Function, args:Array<any>, context:any){
        this.func = f;
        this.args = args;
        this.context = context;
    }

    public apply():Observable<any> {
        return this.func.apply(this.context, this.args);
    }

}
