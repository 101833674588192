import { EnhancedModel } from '../enhanced.model';
import { Service } from './service.model';

export class Location extends EnhancedModel {

    private _id: number = 0;
    public get id(): number {
        return this._id;
    }
    public set id(value: number) {
        this._id = value;
    }

    private _name: string = '';
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    private _shortName: string = '';
    public get shortName(): string {
        return this._shortName;
    }
    public set shortName(value: string) {
        this._shortName = value;
    }

    /**
     * First line address
     */
    private _address: string = '';
    public get address(): string {
        return this._address;
    }
    public set address(value: string) {
        this._address = value;
    }

    /**
     * Second line address
     */
    private _addressAdditional: string = '';
    public get addressAdditional(): string {
        return this._addressAdditional;
    }
    public set addressAdditional(value: string) {
        this._addressAdditional = value;
    }

    private _city: string = '';
    public get city(): string {
        return this._city;
    }
    public set city(value: string) {
        this._city = value;
    }

    private _state: string = '';
    public get state(): string {
        return this._state;
    }
    public set state(value: string) {
        this._state = value;
    }

    private _postalCode: string = '';
    public get postalCode(): string {
        return this._postalCode;
    }
    public set postalCode(value: string) {
        this._postalCode = value;
    }

    private _countryCode: string = '';
    public get countryCode(): string {
        return this._countryCode;
    }
    public set countryCode(value: string) {
        this._countryCode = value;
    }

    private _geoLatitude: string = null;
    public get geoLatitude(): string {
        return this._geoLatitude;
    }
    public set geoLatitude(value: string) {
        this._geoLatitude = value;
    }

    private _geoLongitude: string = null;
    public get geoLongitude(): string {
        return this._geoLongitude;
    }
    public set geoLongitude(value: string) {
        this._geoLongitude = value;
    }

    private _phoneNumber: string = '';
    public get phoneNumber(): string {
        return this._phoneNumber;
    }
    public set phoneNumber(value: string) {
        this._phoneNumber = value;
    }

    private _phoneAreaCode: string = '';
    public get phoneAreaCode(): string {
        return this._phoneAreaCode;
    }
    public set phoneAreaCode(value: string) {
        this._phoneAreaCode = value;
    }

    private _phoneRegionalCode: string = '';
    public get phoneRegionalCode(): string {
        return this._phoneRegionalCode;
    }
    public set phoneRegionalCode(value: string) {
        this._phoneRegionalCode = value;
    }

    private _phoneCountryCode: string = '1';
    public get phoneCountryCode(): string {
        return this._phoneCountryCode;
    }
    public set phoneCountryCode(value: string) {
        this._phoneCountryCode = value;
    }

    private _phoneExtension: string = '';
    public get phoneExtension(): string {
        return this._phoneExtension;
    }
    public set phoneExtension(value: string) {
        this._phoneExtension = value;
    }

    private _faxNumber: string = '';
    public get faxNumber(): string {
        return this._faxNumber;
    }
    public set faxNumber(value: string) {
        this._faxNumber = value;
    }

    private _faxAreaCode: string = '';
    public get faxAreaCode(): string {
        return this._faxAreaCode;
    }
    public set faxAreaCode(value: string) {
        this._faxAreaCode = value;
    }

    private _faxRegionalCode: string = '';
    public get faxRegionalCode(): string {
        return this._faxRegionalCode;
    }
    public set faxRegionalCode(value: string) {
        this._faxRegionalCode = value;
    }

    private _faxCountryCode: string = '';
    public get faxCountryCode(): string {
        return this._faxCountryCode;
    }
    public set faxCountryCode(value: string) {
        this._faxCountryCode = value;
    }

    private _faxExtension: string = '';
    public get faxExtension(): string {
        return this._faxExtension;
    }
    public set faxExtension(value: string) {
        this._faxExtension = value;
    }

    private _temporary: boolean = false;
    public get temporary(): boolean {
        return this._temporary;
    }
    public set temporary(value: boolean) {
        this._temporary = value;
    }

    private _inviteOnly: boolean = false;
    public get inviteOnly(): boolean {
        return this._inviteOnly;
    }
    public set inviteOnly(value: boolean) {
        this._inviteOnly = value;
    }

    private _operational: boolean = true;
    public get operational(): boolean {
        return this._operational;
    }
    public set operational(value: boolean) {
        this._operational = value;
    }

    private _directions: string = '';
    public get directions(): string {
        return this._directions;
    }
    public set directions(value: string) {
        this._directions = value;
    }

    private _notes: string = '';
    public get notes(): string {
        return this._notes;
    }
    public set notes(value: string) {
        this._notes = value;
    }

    private _services:Array<Service> = Array<Service>();
    public get services(): Array<Service> {
        return this._services;
    }
    public set services(value: Array<Service>) {
        this._services = value;
    }

    private _tzData: string = '';
    public get tzData(): string {
        return this._tzData;
    } public set tzData(value: string) {
        this._tzData = value;
    }

    private _specialInstructions: string = '';
    public get specialInstructions(): string {
        return this._specialInstructions;
    }
    public set specialInstructions(value: string) {
        this._specialInstructions = value;
    }

    constructor(obj: any = null) {
        super();
        if (obj !== null) {
            if (obj instanceof Location) {
                this.mapClone(obj);
            } else {
                this.mapJson(obj);
            }
        }
    }

    public clone(): Location {
        let cloned = new Location(this);
        return cloned;
    }

    protected mapClone(obj: Location) {
        this.id = obj.id;
        this.name = obj.name;
        this.address = obj.address;
        this.addressAdditional = obj.addressAdditional;
        this.city = obj.city;
        this.state = obj.state;
        this.postalCode = obj.postalCode;
        this.countryCode = obj.countryCode;
        this.phoneNumber = obj.phoneNumber;
        this.phoneAreaCode = obj.phoneAreaCode;
        this.phoneRegionalCode = obj.phoneRegionalCode;
        this.phoneCountryCode = obj.phoneCountryCode;
        this.phoneExtension = obj.phoneExtension;
        this.faxNumber = obj.faxNumber;
        this.faxAreaCode = obj.faxAreaCode;
        this.faxRegionalCode = obj.faxRegionalCode;
        this.faxCountryCode = obj.faxCountryCode;
        this.faxExtension = obj.faxExtension;
        this.temporary = obj.temporary;
        this.inviteOnly = obj.inviteOnly;
        this.operational = obj.operational;
        this.directions = obj.directions;
        this.notes = obj.notes;
        this.services = obj.services;
        this.shortName = obj.shortName;
        this._tzData = obj.tzData;
        this._specialInstructions = obj.specialInstructions;
    }

    protected mapJson(obj: any) {

        if (obj.hasOwnProperty('id')) {
            this.id = obj.id;
        }
        if (obj.hasOwnProperty('name')) {
            this.name = obj.name;
        }
        if (obj.hasOwnProperty('address')) {
            this.address = obj.address;
        }
        if (obj.hasOwnProperty('addressAdditional')) {
            this.addressAdditional = obj.addressAdditional;
        }
        if (obj.hasOwnProperty('city')) {
            this.city = obj.city;
        }
        if (obj.hasOwnProperty('state')) {
            this.state = obj.state;
        }
        if (obj.hasOwnProperty('postalCode')) {
            this.postalCode = obj.postalCode;
        }
        if (obj.hasOwnProperty('countryCode')) {
            this.countryCode = obj.countryCode;
        }
        if (obj.hasOwnProperty('phoneNumber')) {
            this.phoneNumber = obj.phoneNumber;
        }
        if (obj.hasOwnProperty('phoneAreaCode')) {
            this.phoneAreaCode = obj.phoneAreaCode;
        }
        if (obj.hasOwnProperty('phoneRegionalCode')) {
            this.phoneRegionalCode = obj.phoneRegionalCode;
        }
        if (obj.hasOwnProperty('phoneCountryCode')) {
            this.phoneCountryCode = obj.phoneCountryCode;
        }
        if (obj.hasOwnProperty('phoneExtension')) {
            this.phoneExtension = obj.phoneExtension;
        }
        if (obj.hasOwnProperty('faxNumber')) {
            this.faxNumber = obj.faxNumber;
        }
        if (obj.hasOwnProperty('faxAreaCode')) {
            this.faxAreaCode = obj.faxAreaCode;
        }
        if (obj.hasOwnProperty('faxRegionalCode')) {
            this.faxRegionalCode = obj.faxRegionalCode;
        }
        if (obj.hasOwnProperty('faxCountryCode')) {
            this.faxCountryCode = obj.faxCountryCode;
        }
        if (obj.hasOwnProperty('faxExtension')) {
            this.faxExtension = obj.faxExtension;
        }
        if (obj.hasOwnProperty('temporary')) {
            this.temporary = obj.temporary;
        }
        if (obj.hasOwnProperty('inviteOnly')) {
            this.inviteOnly = obj.inviteOnly;
        }
        if (obj.hasOwnProperty('operational')) {
            this.operational = obj.operational;
        }
        if (obj.hasOwnProperty('directions')) {
            this.directions = obj.directions;
        }
        if (obj.hasOwnProperty('notes')) {
            this.notes = obj.notes;
        }
        if (obj.hasOwnProperty('shortName')) {
            this.shortName = obj.shortName;
        }
        if (obj.hasOwnProperty('services')) {
            obj.services.forEach((service: any) => {
                this.services.push(new Service(service));
            });
        }
        if (obj.hasOwnProperty('tzData')) {
            this._tzData = obj.tzData;
        }
        if (obj.hasOwnProperty('notes')) {
            this._specialInstructions = obj.notes;
        }

    }
}
