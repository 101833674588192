import { Attachment } from './attachment.model';

export class ReconsiderationRequest {
  public id: string;
  public passId: string;
  public program: string;
  public reconsiderationStatus: string;
  public requestID: string;
  // public requestId: string;
  public requestReason:string;
  public gesApplicationId:string;

  public programName: string;

  constructor() {
      this.id = '0';
      this.passId="";
      this.program  = '';
      this.reconsiderationStatus  =  '';
      this.requestID  = '';
      this.requestReason  = '';
      this.programName = '';
  }
}
// https://code.tutsplus.com/tutorials/introduction-to-forms-in-angular-4-template-driven-forms--cms-29766
