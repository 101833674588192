import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../services/error-handler.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class CBSAService {

    constructor(private http: HttpClient,
                private errorHandler: ErrorHandlerService) { }

    public getRedirectURL(passId: string, applicationNumber: string) {
        const uri: string = environment.uriEndpoint + environment.apiVersion + 'goesapp/cbsa/redirect-url?' +
            'applicationCode=' + applicationNumber;

        // We don't care about the result
        return  this.http.get(uri).pipe(
            map((res: any) => {
            return res.url;
        }),
        catchError(error => this.errorHandler.handleError(error)));
    }
}
