import { EnhancedModel } from '../enhanced.model';
import Utils from '../../utils/utils';
import * as moment from 'moment-timezone';
// const moment = require('moment-timezone');


export class AppointmentModel extends EnhancedModel  {

    public static get TYPE_CREATE(): string {
        return 'create';
    }
    public static get TYPE_UPDATE(): string {
        return 'update';
    }
    public static get TYPE_DELETE(): string {
        return 'delete';
    }

    id = 0;
    locationId = 0;
    locationName = '';
    locationDirections = '';
    locationPhone = '';
    serviceId = 0;
    serviceName = '';
    slots = 0;
    notes = '';
    eventTypeId = 0;
    eventTypeName = '';
    userId = 0;
    userName = '';
    startDate = '';
    startDateObject: Date;
    startTime = '';
    startDateGES = '';
    startTimeGES = '';
    startTimestamp = '';
    endTimestamp = '';
    lastUpdatedDate = '';
    lastUpdatedDateObject: Date;
    createdDate = '';
    createdDateObject: Date;
    duration = 0;
    applicationCode = '';
    tzData = '';
    remote = false;
    remoteStatusCode = '';
    remoteUrl = '';

    constructor(obj?: any){
        super();
        if (obj instanceof AppointmentModel) {
            this.mapClone(obj);
        }
        else if (obj instanceof Object) {
            // this must be a json object let's deserialize it
            this.mapJson(obj);
        }
    }

    public clone(){
        const cloned: AppointmentModel = new AppointmentModel(this);
        return cloned;
    }

    protected mapClone(obj: AppointmentModel){
        this.id = obj.id;
        this.locationId = obj.locationId;
        this.locationName = obj.locationName;
        this.locationDirections = obj.locationDirections;
        this.locationPhone = obj.locationPhone;
        this.serviceId = obj.serviceId;
        this.serviceName = obj.serviceName;
        this.slots = obj.slots;
        this.notes = obj.notes;
        this.eventTypeId = obj.eventTypeId;
        this.eventTypeName = obj.eventTypeName;
        this.userId = obj.userId;
        this.userName = obj.userName;
        this.startTimestamp = obj.startTimestamp;
        this.startDate = obj.startDate;
        this.startDateObject = obj.startDateObject;
        this.startTime = obj.startTime;
        this.startDateGES = obj.startDateGES;
        this.startTimeGES = obj.startTimeGES;
        this.endTimestamp = obj.endTimestamp;
        this.lastUpdatedDate = obj.lastUpdatedDate;
        this.createdDate = obj.createdDate;
        this.createdDateObject = obj.createdDateObject;
        this.duration = obj.duration;
        this.applicationCode = obj.applicationCode;
        this.tzData = obj.tzData;
        this.remote = obj.remote;
        this.remoteStatusCode = obj.remoteStatusCode;
        this.remoteUrl = obj.remoteUrl;
    }

    /*

    // old

    {
        "id": 1,
        "user": {
            "id": 1,
            "name": "TT User"
        },
        "location": {
            "id": 1,
            "name": "RRB TT Center",
            "directions": null,
            "notes": "Note our changed seasonal hours.",
            "phoneNumber": "1002000",
            "phoneAreaCode": "202",
            "phoneCountryCode": "1",
            "phoneExtension": null,
            "mapFileName": "http://scheduler/HWAPK32M.png"
        },
        "service": {
            "id": 1,
            "name": "Global Entry"
        },
        "withUser": {
            "id": 2,
            "name": "Location Agent A"
        },
        "notes": "Here are some appointment notes.",
        "eventType": {
            "id": 1,
            "name": "Scheduled appointment"
        },
        "duration": 30,
        "startTimestamp": "2017-04-03T14:30",
        "endTimestamp": "2017-04-03T15:00",
        "applicationCode": "ABJW235LAIH"
    }

    // new

    [
        {
            "id": 18,
            "user": {
                "id": 1,
                "name": "TT User"
            },
            "location": {
                "id": 1,
                "name": "RRB TT Center",
                "notes": "Note our changed seasonal hours.",
                "phoneNumber": "1002000",
                "phoneAreaCode": "202",
                "phoneCountryCode": "1",
                "mapFileName": "http://scheduler/testfile.png"
            },
            "service": {
                "id": 1,
                "name": "Global Entry"
            },
            "startTimestamp": "2017-07-04T08:30",
            "endTimestamp": "2017-07-04T08:45",
            "lastUpdatedDate": "2017-06-06T15:03:07",
            "createdDate": "2017-06-06T15:03:07",
            "eventType": {
                "id": 1,
                "name": "Scheduled appointment",
                "lastUpdatedBy": null,
                "lastUpdatedDate": "2017-06-05T04:28:18",
                "createdDate": "2017-06-05T04:28:18",
                "cancelsAppointment": false
            },
            "duration": 15,
            "locationId": 1,
            "applicationCode": "665"
        }
    ]

    */

    protected mapJson(jsonObject: any){
        if (jsonObject.hasOwnProperty('id')) {
            this.id = jsonObject.id;
        }
        if (jsonObject.hasOwnProperty('location')) {
            if (jsonObject.location.hasOwnProperty('id')) {
                this.locationId = jsonObject.location.id;
            }
            if (jsonObject.location.hasOwnProperty('name')){
                this.locationName = jsonObject.location.name;
            }
            if (jsonObject.location.hasOwnProperty('directions')){
                this.locationDirections = jsonObject.location.directions;
            }

            if (jsonObject.location.hasOwnProperty('phoneNumber')){
                const phoneNumber: string = jsonObject.location.phoneNumber !== null ? jsonObject.location.phoneNumber : '';
                const phoneCountryCode: string = jsonObject.location.hasOwnProperty('phoneCountryCode')
                        ? jsonObject.location.phoneCountryCode : '';
                const phoneExtension: string = jsonObject.location.hasOwnProperty('phoneExtension') ? jsonObject.location.phoneExtension : '';
                if (phoneCountryCode !== null && phoneCountryCode.length > 0){
                    this.locationPhone = phoneCountryCode + '-';
                }
                this.locationPhone += phoneNumber;
                if (phoneExtension !== null && phoneExtension.length > 0){
                    this.locationPhone += ' Ext. ' + phoneExtension;
                }
            }
            if (jsonObject.location.hasOwnProperty('tzData')) {
                this.tzData = jsonObject.location.tzData;
            }
        }
        if (jsonObject.hasOwnProperty('service')) {
            if (jsonObject.service.hasOwnProperty('id')) {
                this.serviceId = jsonObject.service.id;
            }
            if (jsonObject.service.hasOwnProperty('name')){
                this.serviceName = jsonObject.service.name;
            }
        }
        if (jsonObject.hasOwnProperty('slots')) {
            this.slots = jsonObject.slots;
        }
        if (jsonObject.hasOwnProperty('notes')) {
            this.notes = jsonObject.notes;
        }
        if (jsonObject.hasOwnProperty('eventType')) {
            if (jsonObject.eventType.hasOwnProperty('id')) {
                this.eventTypeId = jsonObject.eventType.id;
            }
            if (jsonObject.eventType.hasOwnProperty('name')){
                this.eventTypeName = jsonObject.eventType.name;
            }
        }
        if (jsonObject.hasOwnProperty('user')) {
            if (jsonObject.user.hasOwnProperty('id')) {
                this.userId = jsonObject.user.id;
            }
            if (jsonObject.user.hasOwnProperty('name')){
                this.userName = jsonObject.user.name;
            }
        }
        if (jsonObject.hasOwnProperty('startTimestamp')) {
            this.startTimestamp = jsonObject.startTimestamp + ':00'; // add seconds
            const date: Date = Utils.parseISOLocal(this.startTimestamp); // new Date(this.startTimestamp);
            this.startDateObject = date;
            this.startDate = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
            if (date.getHours() > 12) {
                this.startTime = date.getHours() - 12 + ':';
            } else {
                this.startTime = date.getHours() + ':';
            }
            if (date.getMinutes() < 10) {
                this.startTime += '0' + date.getMinutes();
            } else {
                this.startTime += date.getMinutes();
            }
            this.startDateGES = Utils.extractYYYMMDDFromDate(date);
            this.startTimeGES = Utils.extractHHMMFromDate(date, false);
        }
        if (jsonObject.hasOwnProperty('endTimestamp')) {
            this.endTimestamp = jsonObject.endTimestamp + ':00'; // add seconds
        }
        if (jsonObject.hasOwnProperty('lastUpdatedDate')) {
            const tzDate = moment.tz(jsonObject.lastUpdatedDate, this.tzData);
            this.lastUpdatedDate = tzDate.tz('UTC').format();
            this.lastUpdatedDateObject = new Date(this.lastUpdatedDate);
        }
        if (jsonObject.hasOwnProperty('createdDate')) {
            const tzDate = moment.tz(jsonObject.createdDate, this.tzData);
            this.createdDate = tzDate.tz('UTC').format();
            this.createdDateObject = new Date(this.createdDate);
        }
        if (jsonObject.hasOwnProperty('duration')) {
            this.duration = jsonObject.duration;
        }
        if (jsonObject.hasOwnProperty('applicationCode')) {
            this.applicationCode = jsonObject.applicationCode;
        }

        if (jsonObject.hasOwnProperty('remote')) {
            this.remote = jsonObject.remote;
        }
        if (jsonObject.hasOwnProperty('remoteStatusCode')) {
            this.remoteStatusCode = jsonObject.remoteStatusCode;
        }
        if (jsonObject.hasOwnProperty('remoteUrl')) {
            this.remoteUrl = jsonObject.remoteUrl;
        }

    }

}
