import { EnhancedModel } from '../enhanced.model';

export class Service extends EnhancedModel {

    private _id: number = 0;
    public get id(): number {
        return this._id;
    }
    public set id(value: number) {
        this._id = value;
    }

    private _name: string = '';
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    private _operational: boolean = true;
    public get operational(): boolean {
        return this._operational;
    }
    public set operational(value: boolean) {
        this._operational = value;
    }

    constructor(obj: any = null) {
        super();
        if (obj !== null) {
            if (obj instanceof Service) {
                this.mapClone(obj);
            } else {
                this.mapJson(obj);
            }
        }
    }

    public clone(): Service {
        let cloned = new Service(this);
        return cloned;
    }

    protected mapClone(obj: Service) {
        this.id = obj.id;
        this.name = obj.name;
        this.operational = obj.operational;
    }

    protected mapJson(obj: any) {
        // non-standard Location object lets map the properties
        if (obj.hasOwnProperty('id')) {
            this.id = obj.id;
        }
        if (obj.hasOwnProperty('name')) {
            this.name = obj.name;
        }
        if (obj.hasOwnProperty('operational')) {
            this.operational = obj.operational;
        }
    }

}
