import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from '../services/error-handler.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class PaymentHistoryService {

    public payments : any;
    public singlePayment : any;

    constructor(private http : HttpClient,
                private errorHandler: ErrorHandlerService){}


    getPayments() : any {
        return this.payments;
    }

    getSinglePayment() : any {
        return this.singlePayment;
    }

    pullPayments(userId : string) : Observable<any>{
//        let tempPayments = [
//            {
//                "paymentId" : "12335",
//                "paymentDate" : "2017-03-08",
//                "paymentDescriptions" : [
//                    {"descriptionTitle" : "Renewal - Global Entry"}
//                ]
//            },
//            {
//                "paymentId" : "54321",
//                "paymentDate" : "2015-06-15",
//                "paymentDescriptions" : [
//                    {"descriptionTitle" : "Card Replacement"}
//                ]
//            },
//            {
//                "paymentId" : "098765",
//                "paymentDate" : "2013-06-24",
//                "paymentDescriptions" : [
//                    {"descriptionTitle" : "Vehicle Enrollment"},
//                    {"descriptionTitle" : "APEC Business Travel Card"}
//
//                ]
//            },
//            {
//                "paymentId" : "0987123",
//                "paymentDate" : "2013-02-12",
//                "paymentDescriptions" : [
//                    {"descriptionTitle" : "Global Entry"}
//                ]
//            }
//        ];

  //   return Observable.of(tempPayments);
        return this.http.get(environment.uriEndpoint + environment.apiVersion + 'goesapp/user/payments/' + userId).pipe(
            map( (res:any) => {
                if(this.errorHandler.checkErrorCode(res)){
                    this.payments = res.data;
                    return res.data;
                }
                return null;
            }),
            catchError(error => this.errorHandler.handleError(error)));
    }


    pullSinglePayment(paymentId : string) : Observable<any>{
        return this.http.get(environment.uriEndpoint + environment.apiVersion + 'goesapp/payment/summary/' + paymentId).pipe(
            map( (res:any)=> {
                if(this.errorHandler.checkErrorCode(res)){
                    this.singlePayment = res.data;
                    return res.data;
                }
                return null;
            }),
            catchError(error => this.errorHandler.handleError(error)));
//        let tempPayment = {
//            "paymentDate" : "2016-03-30",
//            "lastName" : "Rossi Sr.",
//            "firstName" : "Michael",
//            "totalPaymentAmount" : "150.00",
//            "creditCardAuthCode" : "A1B1C1",
//            "paymentTrackingId" : "10168195",
//            "dateOfBirth" : "1960-08-01",
//            "paymentMethod" : "Credit Card",
//            "applications": [
//                {
//                    "applicationName" : "Global Online Enrollment System",
//                    "applicationId" : "54321",
//                    "paymentAmount" : "100.00",
//                },
//                {
//                    "applicationName" : "Nexus",
//                    "applicationId" : "55555",
//                    "paymentAmount" : "50.00",
//                }
//            ]
//        };
//        this.singlePayment = tempPayment;
//        //console.log(this.singlePayment);
//        return Observable.of(tempPayment);
 }

    public getTTPPaymentsForApplication(userId: string, applicationId: string) : Observable<any> {
        const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/' + applicationId + '/payments';

        return this.http.get(requestURI).pipe(
            map( (res:any)=> {
                return res;
            }),
            catchError(error => this.errorHandler.handleError(error)));

    }
 }
