export class SchedulerModel {

    /**
     * Progress state of this component
     * START
     */
    public static get START(): string { return 'start'; }
    public static get START_ABORT(): string { return 'start abort'; }
    public static get SERVICE_ERROR(): string { return 'service error'; }
    public static get PARAM_ERROR(): string { return 'params error'; }

    /**
     * Incoming params from scheduler landing page
     */
    public static get STATUS_SCHEDULED(): string { return 'scheduled'; }
    public static get STATUS_RESCHEDULED(): string { return 'rescheduled'; }
    public static get STATUS_WILL_SCHEDULE(): string { return 'will-schedule'; }
    public static get STATUS_WILL_RESCHEDULE(): string { return 'will-reschedule'; }
    public static get STATUS_CANCELLED(): string { return 'cancelled'; }
    public static get STATUS_ABORTED(): string { return 'aborted'; }
    public static get STATUS_ERROR(): string { return 'error'; }
    public static get STATUS_SESSION(): string { return 'session'; }

    /**
     * GES schedule types
     */
    public static get GES_STATUS_SCHEDULED(): string { return 'sch'; }
    public static get GES_STATUS_RESCHEDULED(): string { return 'sch'; }
    public static get GES_STATUS_CANCELLED(): string { return 'cna'; }

    public static get INTERVIEW_STATUS_CANCELLED(): string { return 'IC'; }
    public static get INTERVIEW_STATUS_SCHEDULED(): string { return 'IS'; }

    public static get APPOINTMENT_TYPE_CREATE_ID(): number { return 1; }
    public static get APPOINTMENT_TYPE_RESCHEDULE_ID(): number { return 2; }
    public static get APPOINTMENT_TYPE_CANCEL_ID(): number { return 4; }
    public static get APPOINTMENT_TYPE_OFFICER_CREATE_ID(): number { return 8; }

    public status = '';
    public userId = '';
    public token = '';
    public appointmentStartTimestamp = '';
    public appointmentEndTimestamp = '';
    public appointmentLocationId = '';
    public applicationId = '';
    public reason = '';
    public source = '';
    public appointmentLocationName = '';

    public tzData = '';

    constructor(){
    }

    public isLoginDataSet(): boolean {
        const success: boolean = (this.userId.length > 0) ? true : false;
        return success;
    }

    // todo: more validation needed for timestamp
    public isWillCreateNewAppointmentDataSet(): boolean {
        const statusSuccess: boolean = (this.status === SchedulerModel.STATUS_WILL_SCHEDULE) ? true : false;
        const dateSuccess: boolean = (this.appointmentStartTimestamp.length > 0 && this.appointmentEndTimestamp.length > 0) ? true : false;
        const idSuccess: boolean = (this.appointmentLocationId.length > 0 && this.applicationId.length > 0) ? true : false;
        const success: boolean = (dateSuccess && idSuccess && statusSuccess) ? true : false;
        return success;
    }

    public isWillUpdateAppointmentDataSet(): boolean {
        const statusSuccess: boolean = (this.status === SchedulerModel.STATUS_WILL_RESCHEDULE) ? true : false;
        const dateSuccess: boolean = (this.appointmentStartTimestamp.length > 0 && this.appointmentEndTimestamp.length > 0) ? true : false;
        const idSuccess: boolean = (this.appointmentLocationId.length > 0 && this.applicationId.length > 0) ? true : false;
        const success: boolean = (dateSuccess && idSuccess && statusSuccess) ? true : false;
        return success;
    }

}
